.features-tiles-item-image {
    display: inline-flex;
    padding: 14px;
	border-radius: 50%;
    background: get-color(primary, 1);  
    // Kyle additions

    
}

.icon-invert {
    // filter: invert(1); /* Invert colors */
    padding: 10px;
    display: block;
    margin: auto; /* Center the icon */
}  

.features-tiles-item-content {

    h4 {
        @include font-family(heading);
    }
}